html {
  height: 100%;
}

body {
  margin: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  font-family: Roboto, Segoe UI, sans-serif;
  -ms-content-zooming: none;
  touch-action: none;
  overscroll-behavior: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.rightShadow {
  box-shadow: 2px 0px 4px -1px rgba(0, 0, 0, 0.2),
    4px 0px 5px 0px rgba(0, 0, 0, 0.14), 1px 0px 10px 0px rgba(0, 0, 0, 0.12);
}

.thingMainBG {
  background-image: url("./assets/background-logo.svg");
}

.notSelectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.defaultCursor {
  cursor: default;
}

.nonDraggable {
  -webkit-user-drag: none;
}

.plotHighlight {
  transform: translateY(10px);
}

input[type="text"]::-ms-clear {
  display: none;
}

input[type="password"]::-ms-reveal {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::selection {
  background: #0083ff; /* WebKit/Blink Browsers */
  color: #fff;
}

::-moz-selection {
  background: #0083ff; /* Gecko Browsers */
  color: #fff;
}
